import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { useMemo } from "react";
import useDirections from "../../../hooks/functionality/useDirections";
import { StopDraft, StopDraftsTour } from "../../../shared/types/api";
import {
    getLatLngCountMap,
    getLocationKey,
} from "../../../shared/utility/misc";
import {
    getStopOrderMap,
    getStopStatus,
} from "../../../shared/utility/stop-draft";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import "./style.scss";

type Props = {
    tour?: StopDraftsTour;
    stops?: StopDraft[];
    tourColor?: {
        primary: string;
        accent: string;
    };
    disableDirections?: boolean;
    alwaysShowPositionNumber?: boolean;
};

function TourMarkers(props: Props) {
    const stopOrderMap = useMemo(() => {
        const stopList = props.stops || props.tour?.stops;
        if (!stopList) return {};
        return getStopOrderMap(stopList);
    }, [props.stops, props.tour?.stops]);

    const mapPoints = useMemo(() => {
        const stopList = props.stops || props.tour?.stops;

        if (!stopList) return [];

        return stopList.map((stop, i) => {
            const stopStatus = getStopStatus(stop);
            const stopLabel =
                stopOrderMap[
                    stop.motion_tools_stop_group || stop.id.toString()
                ] + 1;

            return {
                label: stopLabel,
                location: {
                    lat: +stop.lat!,
                    lng: +stop.lng!,
                },
                stopStatus,
            };
        });
    }, [props.stops, props.tour?.stops, stopOrderMap]);

    const { completedPoints, incompletedPoints } = useMemo(() => {
        const completedPoints = mapPoints.filter(
            (point) => point.stopStatus.isCompleted
        );
        const incompletePoints = mapPoints.filter(
            (point) => !point.stopStatus.isCompleted
        );
        const lastCompletedPoint = completedPoints[completedPoints.length - 1];

        if (lastCompletedPoint) {
            incompletePoints.unshift(lastCompletedPoint);
        }
        return {
            completedPoints: completedPoints.map((point) => point.location),
            incompletedPoints: incompletePoints.map((point) => point.location),
        };
    }, [mapPoints]);

    useDirections({
        path: incompletedPoints,
        directionsColor: props.tourColor?.primary,
        hide: props.disableDirections,
        zIndex: 2,
    });

    useDirections({
        path: completedPoints,
        directionsColor: props.tourColor?.accent,
        hide: props.disableDirections,
        zIndex: 1,
    });

    const latLngStopCounts = useMemo(
        () => getLatLngCountMap(mapPoints.map((point) => point.location)),
        [mapPoints]
    );

    const motionVariants = {
        hidden: { opacity: 0, scale: 0.5 },
        visible: { opacity: 1, scale: 1 },
    };

    return (
        <>
            {mapPoints.map((point, i) => (
                <AdvancedMarker
                    key={i}
                    position={point.location}
                    zIndex={mapPoints.length - i}
                >
                    <motion.div
                        variants={motionVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        className="tour-marker"
                        style={{
                            backgroundColor: props.tourColor?.primary,
                        }}
                    >
                        {latLngStopCounts[getLocationKey(point.location)] >
                        1 ? (
                            <div
                                className="count"
                                style={{
                                    color: props.tourColor?.primary,
                                    backgroundColor: props.tourColor?.accent,
                                }}
                            >
                                {
                                    latLngStopCounts[
                                        getLocationKey(point.location)
                                    ]
                                }
                            </div>
                        ) : null}
                        {point.stopStatus.isCompleted &&
                        !props.alwaysShowPositionNumber ? (
                            <FontAwesomeIcon icon={faCheck} />
                        ) : (
                            <span className="text-xs">{point.label}</span>
                        )}
                    </motion.div>
                </AdvancedMarker>
            ))}
        </>
    );
}

export default TourMarkers;
