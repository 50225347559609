import { addMinutes } from "date-fns";
import { useTranslation } from "react-i18next";
import TextButton from "../../../components/buttons/TextButton";
import Checkbox from "../../../components/inputs/Checkbox";
import Badge from "../../../components/UI/Badge";
import ProgressBar from "../../../components/UI/ProgressBar";
import Table from "../../../containers/Table";
import { TourSort } from "../../../hooks/functionality/useStopAndTourFilterAndSort";
import useUserFeatures from "../../../hooks/functionality/useUserFeatures";
import { TourTableData } from "../../../pages/BigVolume";
import { StopDraft } from "../../../shared/types/api";
import { dateToString, stringToDate } from "../../../shared/utility/date";
import { getStopLocationText } from "../../../shared/utility/stop-draft";
import {
    getNextStop,
    getTourEndDate,
    getTourEndDateWithStartDate,
    getTourProgress,
    getTourStatus,
} from "../../../shared/utility/tour";
import "./style.scss";

type Props = {
    data: TourTableData[];

    // selected tours
    selectedTourIds: number[];
    onSelectedTourIdsChange: (value: boolean, tourId: number) => void;

    // sorting
    activeSort: TourSort;
    onSortChange: (sort: TourSort) => void;
    onRowClickStops: (stops: { pickup: StopDraft; dropoff: StopDraft }) => void;

    // actions
    onGroupTourClick: (tourId: number) => void;

    sectionsSize: "quarter" | "half" | "full";
};

function TourTable(props: Props) {
    const { t } = useTranslation();
    const features = useUserFeatures();

    return (
        <Table
            data={props.data}
            emptyStateLabel={t("bigVolume.emptyToursLabel")}
            maxHeight={
                props.sectionsSize === "quarter"
                    ? "var(--section-height)"
                    : "var(--section-height-full)"
            }
            onRowClick={({ stops, tour }) =>
                stops
                    ? props.onRowClickStops(stops)
                    : tour
                    ? props.onSelectedTourIdsChange(
                          !props.selectedTourIds.includes(tour.tour_id),
                          tour.tour_id
                      )
                    : undefined
            }
            renderModalContent={({ tour }, close) => {
                if (!tour || !features?.manage_tours_fleet_planner) return null;

                return (
                    <TextButton
                        variant={"secondary"}
                        label={t("bigVolume.groupStops")}
                        onClick={(e) => {
                            e.stopPropagation();
                            props.onGroupTourClick(tour.tour_id);
                            close();
                        }}
                        style={{
                            width: "150px",
                            height: "30px",
                        }}
                    />
                );
            }}
            columns={[
                {
                    key: "tour_id",
                    width: "50px",
                    title: (
                        <Checkbox
                            disabled
                            checked={false}
                            onChange={() => null}
                            style={{
                                padding: "6px",
                            }}
                        />
                    ),
                    render: ({ tour }) =>
                        tour ? (
                            <Checkbox
                                checked={props.selectedTourIds.includes(
                                    tour.tour_id
                                )}
                                onChange={(value) =>
                                    props.onSelectedTourIdsChange(
                                        value,
                                        tour.tour_id
                                    )
                                }
                                style={{
                                    padding: "12px 6px",
                                }}
                            />
                        ) : (
                            <Checkbox
                                checked={false}
                                onChange={() => {}}
                                disabled
                                style={{
                                    padding: "12px 6px",
                                }}
                            />
                        ),
                },
                {
                    key: "preferred_driver",
                    title: t("bigVolume.driver"),
                    width: "170px",
                    sortMode:
                        props.activeSort === "driver-asc"
                            ? "asc"
                            : props.activeSort === "driver-desc"
                            ? "desc"
                            : null,
                    getValue: ({ tour, stops }) =>
                        tour
                            ? tour.preferred_driver || "-"
                            : stops?.pickup.order
                                  ?.carrier_delivery_requests?.[0]
                            ? stops.pickup.order.carrier_delivery_requests[0]
                                  .external_carrier?.name || "-"
                            : stops?.pickup.location?.name
                            ? stops.pickup.location.name
                            : "-",
                    onSort: () =>
                        props.onSortChange(
                            props.activeSort === "driver-desc"
                                ? "driver-asc"
                                : "driver-desc"
                        ),
                },
                {
                    key: "status",
                    title: t("bigVolume.tourStatus"),
                    width: "170px",
                    render: ({ tour, stops }) => {
                        if (tour) {
                            return getTourStatus(tour) === "planned" ? (
                                <Badge
                                    variant="neutral"
                                    title={t("bigVolume.tourNotStarted")}
                                />
                            ) : (
                                <ProgressBar
                                    progress={getTourProgress(tour).progress}
                                    minWidth="70px"
                                />
                            );
                        }

                        if (stops) {
                            if (
                                stops.pickup.order
                                    ?.carrier_delivery_requests?.[0]
                            ) {
                                return (
                                    <Badge
                                        variant={
                                            stops.pickup.order
                                                .carrier_delivery_requests[0]
                                                .accepted_at
                                                ? "success"
                                                : stops.pickup.order
                                                      .carrier_delivery_requests[0]
                                                      .declined_at
                                                ? "error"
                                                : "neutral"
                                        }
                                        title={
                                            stops.pickup.order
                                                .carrier_delivery_requests[0]
                                                .accepted_at
                                                ? t("externalOrder.accepted")
                                                : stops.pickup.order
                                                      .carrier_delivery_requests[0]
                                                      .declined_at
                                                ? t("externalOrder.declined")
                                                : t("externalOrder.pending")
                                        }
                                    />
                                );
                            }

                            if (stops.pickup.location) {
                                return (
                                    <Badge
                                        variant={
                                            stops.pickup.move_accepted_at
                                                ? "success"
                                                : "neutral"
                                        }
                                        title={
                                            stops.pickup.move_accepted_at
                                                ? t(
                                                      "fleetPlanner.assignedAccepted"
                                                  )
                                                : t(
                                                      "fleetPlanner.assignedNotAccepted"
                                                  )
                                        }
                                    />
                                );
                            }
                        }

                        return null;
                    },
                    sortMode:
                        props.activeSort === "progress-asc"
                            ? "asc"
                            : props.activeSort === "progress-desc"
                            ? "desc"
                            : null,
                    onSort: () =>
                        props.onSortChange(
                            props.activeSort === "progress-desc"
                                ? "progress-asc"
                                : "progress-desc"
                        ),
                },
                {
                    key: "end_time",
                    title: t("bigVolume.tourEndTime"),
                    width: "130px",
                    getValue: ({ tour, stops }) => {
                        if (stops) {
                            let stopEndDate: Date | null = null;

                            if (stops.dropoff.arrived_at) {
                                stopEndDate = stringToDate(
                                    stops.dropoff.arrived_at
                                );
                            } else if (stops.dropoff.eta_internal) {
                                stopEndDate = stringToDate(
                                    stops.dropoff.eta_internal
                                )!;
                            }

                            if (stopEndDate) {
                                return dateToString(
                                    addMinutes(
                                        stopEndDate,
                                        stops.dropoff.estimated_offload_time ||
                                            0
                                    ),
                                    {
                                        onlyTime: true,
                                    }
                                );
                            }

                            return "";
                        }

                        if (!tour) return "";

                        const tourStatus = getTourStatus(tour);

                        if (tourStatus === "active") {
                            return dateToString(getTourEndDate(tour.stops), {
                                onlyTime: true,
                            });
                        }

                        return (
                            dateToString(
                                getTourEndDateWithStartDate(
                                    tour.stops,
                                    stringToDate(
                                        (tour.date || "2020-01-01") +
                                            "T" +
                                            tour.time,
                                        {
                                            localTimezone: true,
                                        }
                                    )!
                                ),
                                {
                                    onlyTime: true,
                                }
                            ) || "-"
                        );
                    },
                    sortMode:
                        props.activeSort === "endtime-asc"
                            ? "asc"
                            : props.activeSort === "endtime-desc"
                            ? "desc"
                            : null,
                    onSort: () =>
                        props.onSortChange(
                            props.activeSort === "endtime-desc"
                                ? "endtime-asc"
                                : "endtime-desc"
                        ),
                },
                {
                    key: "orders",
                    title: t("bigVolume.orders"),
                    width: "100px",
                    getValue: ({ tour }) => (tour ? tour.stops.length / 2 : 1),
                    sortMode:
                        props.activeSort === "orders-asc"
                            ? "asc"
                            : props.activeSort === "orders-desc"
                            ? "desc"
                            : null,
                    onSort: () =>
                        props.onSortChange(
                            props.activeSort === "orders-desc"
                                ? "orders-asc"
                                : "orders-desc"
                        ),
                },
                {
                    key: "next_stop",
                    title: t("bigVolume.nextStop"),
                    width: "100%",
                    getValue: ({ tour }) => {
                        if (!tour) return "";
                        const nextStop = getNextStop(tour);
                        if (!nextStop) return "";
                        return getStopLocationText(nextStop);
                    },
                    sortMode:
                        props.activeSort === "nextstop-asc"
                            ? "asc"
                            : props.activeSort === "nextstop-desc"
                            ? "desc"
                            : null,
                    onSort: () =>
                        props.onSortChange(
                            props.activeSort === "nextstop-desc"
                                ? "nextstop-asc"
                                : "nextstop-desc"
                        ),
                },
            ]}
        />
    );
}

export default TourTable;
